import { debounce, isMatch } from 'lodash';
import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import {
  ClientSearchSDK,
  ISearchProductDocument,
  ISearchRequest,
  ISearchResponse,
  IDemoContentOptions,
  SearchDocumentType,
} from '@wix/client-search-sdk';

import initSchemaLogger, {
  documentClickParams as BiDocumentClickParams,
  Logger,
} from '@wix/bi-logger-wix-search-widget';

import { IWidgetControllerConfig } from '../../../../../lib/platform.types';
import {
  ISearchLocation,
  ILocationSearchRequest,
} from '../../../../../lib/location';
import { getTotalPages } from '../pagination';
import {
  addProductToCart,
  convertProductFacetsFilterToRequestParams,
  convertProductFacetsRequestParamsToFilter,
  extractProductFacetsFromSearchResponse,
  IProductFacetsState,
  IProductFacetsFilter,
} from '../products';
import {
  DocumentTypeChangeSource,
  SearchRequestStatus,
} from '../../types/types';
import {
  createBiCorrelationId,
  BiStoreKey,
  BiStore,
  BiSearchOrigin,
} from '../../../../../lib/bi';
import { Settings } from '../extractSettings';
import {
  DocumentClickOrigin,
  ISearchResultsControllerProps,
  ISearchSample,
  ISeoItemData,
  SearchResultsControllerStoreState,
  VisibleCategories,
} from './SearchResultsControllerStore.types';
import { getOrdering } from '../sort';
import { search } from '../search';
import {
  createSearchRequestBiLogger,
  getBiTotals,
  getBiAvailableFacets,
  getBiSelectedFacets,
} from '../bi';
import { getAbsoluteDocumentIndex } from './getAbsoluteDocumentIndex';
import { equalSearchRequests } from './equalSearchRequests';
import { getVisibleCategories } from './getVisibleCategories';
import { withDocumentType } from './withDocumentType';
import { Spec } from '../../../../../lib/specs';
import { reportError } from '../../../../../lib/errors';
import { DEFAULT_SORT_OPTION } from '../../../../../lib/sort';

export class SearchResultsControllerStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly wixCodeApi: IWixAPI;
  private readonly setComponentProps: (
    props: SearchResultsControllerStoreState,
  ) => void;
  private readonly biLogger: Logger;
  private readonly biStore: BiStore;
  private readonly searchSDK: ClientSearchSDK;
  private readonly searchLocation: ISearchLocation;

  private demoContentOptions!: IDemoContentOptions;
  private documentTypes: SearchDocumentType[];
  private state: SearchResultsControllerStoreState;

  constructor(
    {
      platformAPIs,
      wixCodeApi,
      searchSDK,
      searchLocation,
      setProps,
      flowAPI,
    }: IWidgetControllerConfig,
    settings: Settings,
  ) {
    this.flowAPI = flowAPI;
    this.setComponentProps = setProps;
    this.wixCodeApi = wixCodeApi;
    this.searchSDK = searchSDK;
    this.searchLocation = searchLocation;
    this.documentTypes = [];

    const { errorMonitor, environment } = flowAPI;
    const { language, isViewer } = environment;
    const isDemoContent = !isViewer;
    const locationParams = this.getSearchRequestParamsFromLocation();
    const locale = wixCodeApi.site.regionalSettings || language;

    this.state = {
      ...this.getEmptyResponseStateProps(),
      apiErrorDetails: undefined,
      locale,
      searchResultsAbsoluteUrl: '',
      settings,
      searchRequest: {} as ISearchRequest,
      searchRequestStatus: SearchRequestStatus.Initial,
      documentTypes: [],
      onDocumentTypeChange: this.handleDocumentTypeChange,
      onQuerySubmit: this.handleQuerySubmit,
      onPageChange: this.handlePageChange,
      onSortChange: this.handleSortChange,
      onDocumentClick: this.handleDocumentClick,
      onProductAddToCart: this.handleProductAddToCart,
      onProductFacetsFilterReset: this.handleProductFacetsFilterReset,
      onProductFacetsFilterChange: this.handleProductFacetsFilterChange,
      // TODO: cleanup when resolved https://github.com/wix-private/native-components-infra/pull/28
      viewMode: wixCodeApi.window.viewMode,
      isDemoContent,
      productFacets: {} as IProductFacetsState,
      selectedSortOption: locationParams.sort ?? DEFAULT_SORT_OPTION,
    };

    this.state.searchRequest = this.getSearchRequestFromLocationParams(
      locationParams,
      settings.itemsPerPage,
    );

    this.state.productFacets = {
      ...this.getUpdatedProductFacetsState(
        this.state.searchRequest,
        this.state.searchResponse,
      ),
      filter: {
        minPrice: locationParams.minPrice,
        maxPrice: locationParams.maxPrice,
        collections: locationParams.collections,
      },
    };

    if (isDemoContent) {
      this.setDemoContentOptions({
        shouldHaveSearchResults: true,
      });
    }

    this.biLogger = initSchemaLogger(platformAPIs.biLoggerFactory?.())();
    this.biStore = new BiStore(platformAPIs);

    wixCodeApi.location.onChange(() => {
      try {
        const stateSearchRequest = this.state.searchRequest;
        const locationSearchRequest = this.getSearchRequestFromLocationParams(
          this.getSearchRequestParamsFromLocation(),
          stateSearchRequest.paging.pageSize,
        );
        if (equalSearchRequests(locationSearchRequest, stateSearchRequest)) {
          return;
        }
        this.changeSearchRequest(locationSearchRequest);
      } catch (error) {
        reportError(errorMonitor, error);
      }
    });
  }

  private shouldShowProductFacets(documentType?: SearchDocumentType): boolean {
    const { settings } = this.state;
    const { environment } = this.flowAPI;

    return (
      settings.isProductsFacetsEnabled &&
      documentType === SearchDocumentType.Products &&
      !environment.isEditorX
    );
  }

  private getUpdatedProductFacetsState(
    searchRequest: ISearchRequest,
    searchResponse: ISearchResponse,
  ): IProductFacetsState {
    return {
      ...this.state.productFacets,
      enabled: this.shouldShowProductFacets(searchRequest.documentType),
      filter: convertProductFacetsRequestParamsToFilter(searchRequest),
      ...extractProductFacetsFromSearchResponse(searchResponse),
    };
  }

  private getSearchRequestParamsFromLocation(): ILocationSearchRequest {
    return this.searchLocation.decodeParams();
  }

  private getSearchRequestFromLocationParams(
    locationParams: ILocationSearchRequest,
    pageSize: number,
  ): ISearchRequest {
    return this.searchLocation.toSDKSearchRequest(locationParams, pageSize);
  }

  private setDemoContentOptions(partialOptions: IDemoContentOptions) {
    if (
      this.demoContentOptions &&
      isMatch(this.demoContentOptions, partialOptions)
    ) {
      return;
    }

    this.demoContentOptions = {
      ...this.demoContentOptions,
      ...partialOptions,
    };

    this.searchSDK.useDemoContent(this.demoContentOptions);
  }

  private setState(partialState: Partial<SearchResultsControllerStoreState>) {
    this.state = {
      ...this.state,
      ...partialState,
    };

    this.setComponentProps(this.state);
  }

  private getBiSearchCorrelationId(): string | undefined {
    return this.biStore.get(BiStoreKey.SearchCorrelation) || undefined;
  }

  private getBiSearchOrigin(): string {
    return this.biStore.get(BiStoreKey.SearchOrigin) || BiSearchOrigin.Other;
  }

  private async search(
    searchRequest: ISearchRequest,
    visibleCategories: VisibleCategories,
    previousSearchRequestStatus: SearchRequestStatus,
  ) {
    searchRequest = this.withOrdering(
      withDocumentType(searchRequest, visibleCategories),
    );

    const facetsEnabled = this.shouldShowProductFacets(
      searchRequest.documentType,
    );

    if (facetsEnabled) {
      searchRequest = this.withFacets(searchRequest);
    }

    const { environment, errorMonitor } = this.flowAPI;

    const searchResult = await search({
      searchRequest,
      searchSDK: this.searchSDK,
      previousQuery: this.state.previousQuery,
      environment,
      facetsEnabled,
      previousTotals: this.state.searchResponseTotals,
      previousSearchRequestStatus,
      previousSearchRequest: this.state.searchRequest,
      previousSearchResponse: this.state.searchResponse,
      searchResultsAbsoluteUrl: this.state.searchResultsAbsoluteUrl,
      searchLocation: this.searchLocation,
      visibleCategories,
      biStore: this.biStore,
      biLogger: !environment.isSSR
        ? createSearchRequestBiLogger({
            biLogger: this.biLogger,
            isDemoContent: this.state.isDemoContent,
            searchRequest,
            correlationId: this.getBiSearchCorrelationId(),
            origin: this.getBiSearchOrigin(),
          })
        : undefined,
    });

    if ('isError' in searchResult) {
      reportError(
        errorMonitor,
        new Error(JSON.stringify(searchResult.errorDetails)),
      );
      return {
        ...this.getErrorStateProps(),
        apiErrorDetails: searchResult.errorDetails,
      };
    }

    const {
      searchResponse,
      searchResponseTotals,
      searchSamples,
    } = searchResult;

    // NOTE: wixCodeApi.site.currency can return undefined for some websites - we are
    // extracting currency from products schema instead.
    const currency =
      this.extractCurrencyFromSamples(searchSamples) || this.state.currency;

    const result = {
      apiErrorDetails: undefined,
      currency,
      searchRequest,
      searchResponse,
      searchResponseTotals,
      searchSamples,
      searchRequestStatus: SearchRequestStatus.Loaded,
      previousQuery: searchRequest.query,
      productFacets: this.getUpdatedProductFacetsState(
        searchRequest,
        searchResponse,
      ),
    };

    await this.renderSeo(result);

    return result;
  }

  private async changeSearchRequest(
    searchRequest: ISearchRequest,
  ): Promise<void> {
    const previousSearchRequestStatus = this.state.searchRequestStatus;

    this.setState({
      searchRequestStatus: SearchRequestStatus.Loading,
    });

    const visibleCategories = getVisibleCategories(
      this.state.settings,
      this.documentTypes,
    );

    try {
      const partialState = await this.search(
        searchRequest,
        visibleCategories,
        previousSearchRequestStatus,
      );
      this.setState(partialState);
    } catch (error) {
      this.handleError(error);
    }
  }

  private readonly changeSearchRequestLazy: (
    request: ISearchRequest,
  ) => void = debounce(this.changeSearchRequest, 500);

  updateSettings(settings: Settings) {
    const prevSettings = this.state.settings;

    this.setState({
      settings,
    });

    if (prevSettings.itemsPerPage !== settings.itemsPerPage) {
      this.changeSearchRequestLazy({
        ...this.state.searchRequest,
        paging: {
          page: 1,
          pageSize: settings.itemsPerPage,
        },
      });
    }

    // https://github.com/wix-private/site-search/issues/153
    // this.settingsEventHandler.updateData(appPublicData);
  }

  private applySearchRequest(
    searchRequest: ISearchRequest,
    disableScrollToTop?: boolean,
  ) {
    if (
      this.state.isDemoContent ||
      equalSearchRequests(this.state.searchRequest, searchRequest)
    ) {
      this.changeSearchRequest(searchRequest);
      return;
    }

    this.searchLocation.navigateToSearchResults(
      this.searchLocation.toLocationSearchRequest(searchRequest),
      { disableScrollToTop },
    );
  }

  private changeDocumentType = (
    documentType: SearchDocumentType,
    disableScrollTop?: boolean,
  ) => {
    const { searchRequest } = this.state;

    this.applySearchRequest(
      {
        ...searchRequest,
        documentType,
        filter: undefined,
        ordering: undefined,
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      },
      disableScrollTop,
    );
  };

  changeQuery = (query: string, disableScrollTop?: boolean) => {
    const { searchRequest } = this.state;

    this.state.productFacets.filter = {};

    this.applySearchRequest(
      {
        ...searchRequest,
        query,
        filter: undefined,
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      },
      disableScrollTop,
    );
  };

  private extractCurrencyFromSamples(
    searchSamples: ISearchSample[],
  ): string | undefined {
    const productSamples = (searchSamples.find(
      ({ documentType }) => documentType === SearchDocumentType.Products,
    )?.documents ?? []) as ISearchProductDocument[];

    return productSamples.length > 0 ? productSamples[0].currency : undefined;
  }

  updateDemoMode(data: { shouldHaveSearchResults: boolean }) {
    const { shouldHaveSearchResults } = data;
    const { searchRequest } = this.state;
    let isDemoContentOptionsChanged = false;

    if (
      shouldHaveSearchResults !==
      this.demoContentOptions.shouldHaveSearchResults
    ) {
      this.setDemoContentOptions({
        shouldHaveSearchResults,
      });

      isDemoContentOptionsChanged = true;
    }

    if (isDemoContentOptionsChanged) {
      this.applySearchRequest(searchRequest);
    }
  }

  private readonly handleDocumentTypeChange: ISearchResultsControllerProps['onDocumentTypeChange'] = (
    documentType,
    documentTypeChangeSource,
  ) => {
    try {
      this.biStore.set(BiStoreKey.SearchOrigin, BiSearchOrigin.TabChange);
      this.biLogger.documentTypeChange({
        isDemo: this.state.isDemoContent,
        target: this.state.searchRequest.query,
        correlationId: this.getBiSearchCorrelationId(),
        tabName: documentType,
        source:
          documentTypeChangeSource === DocumentTypeChangeSource.ViewAllButton
            ? 'samples'
            : 'tabs',
      });

      this.changeDocumentType(
        documentType,
        documentTypeChangeSource === DocumentTypeChangeSource.Tab,
      );
    } catch (error) {
      reportError(this.flowAPI.errorMonitor, error);
    }
  };

  private readonly handleQuerySubmit: ISearchResultsControllerProps['onQuerySubmit'] = (
    query,
  ) => {
    try {
      const correlationId = createBiCorrelationId();

      this.biStore.set(BiStoreKey.SearchCorrelation, correlationId);
      this.biStore.set(
        BiStoreKey.SearchOrigin,
        BiSearchOrigin.ResultPageSearchBar,
      );

      this.biLogger.searchSubmit({
        isDemo: this.state.isDemoContent,
        target: query,
        correlationId,
      });

      this.changeQuery(query, true);
    } catch (error) {
      reportError(this.flowAPI.errorMonitor, error);
    }
  };

  private readonly handlePageChange: ISearchResultsControllerProps['onPageChange'] = (
    selectedPage,
  ) => {
    const { searchRequest } = this.state;

    try {
      this.biStore.set(BiStoreKey.SearchOrigin, BiSearchOrigin.PageChange);
      this.applySearchRequest({
        ...searchRequest,
        paging: {
          ...searchRequest.paging,
          page: selectedPage,
        },
      });
    } catch (error) {
      reportError(this.flowAPI.errorMonitor, error);
    }
  };

  private readonly handleDocumentClick: ISearchResultsControllerProps['onDocumentClick'] = (
    searchDocument,
    index,
    clickOrigin,
  ) => {
    const { searchRequest } = this.state;

    try {
      this.logBiDocumentClick({
        documentId: searchDocument.id,
        documentType: searchDocument.documentType,
        pageUrl: searchDocument.url,
        resultClicked: searchDocument.title,
        searchIndex: getAbsoluteDocumentIndex(searchRequest.paging, index),
        clickOrigin,
      });
      this.wixCodeApi.location.to?.(searchDocument.relativeUrl);
    } catch (error) {
      reportError(this.flowAPI.errorMonitor, error);
    }
  };

  private readonly handleSortChange: ISearchResultsControllerProps['onSortChange'] = (
    selectedSortOption,
  ) => {
    if (selectedSortOption === this.state.selectedSortOption) {
      return;
    }

    try {
      const { searchRequest } = this.state;

      this.biStore.set(BiStoreKey.SearchOrigin, BiSearchOrigin.Sort);
      this.setState({ selectedSortOption });
      this.applySearchRequest(
        this.withOrdering({
          ...searchRequest,
          paging: {
            ...searchRequest.paging,
            page: 1,
          },
        }),
        true,
      );
    } catch (error) {
      reportError(this.flowAPI.errorMonitor, error);
    }
  };

  private readonly handleProductAddToCart: ISearchResultsControllerProps['onProductAddToCart'] = async (
    product,
  ) => {
    try {
      this.logBiDocumentClick({
        clickOrigin: 'add_to_cart',
        documentId: product.id,
        documentType: product.documentType,
      });
      await addProductToCart(product, this.wixCodeApi);
    } catch (error) {
      reportError(this.flowAPI.errorMonitor, error);
    }
  };

  private readonly handleProductFacetsFilterReset = () => {
    try {
      this.biStore.set(BiStoreKey.SearchOrigin, BiSearchOrigin.ClearFacets);
      this.logBiClickResetFacets();
      this.changeProductFacetsFilter({
        collections: undefined,
        maxPrice: undefined,
        minPrice: undefined,
      });
    } catch (error) {
      reportError(this.flowAPI.errorMonitor, error);
    }
  };

  private readonly handleProductFacetsFilterChange: ISearchResultsControllerProps['onProductFacetsFilterChange'] = (
    filter,
  ) => {
    try {
      this.biStore.set(BiStoreKey.SearchOrigin, BiSearchOrigin.Facets);
      this.changeProductFacetsFilter(filter);
    } catch (error) {
      reportError(this.flowAPI.errorMonitor, error);
    }
  };

  private changeProductFacetsFilter(filter: IProductFacetsFilter) {
    const { searchRequest, productFacets } = this.state;

    this.setState({
      productFacets: {
        ...productFacets,
        filter: {
          ...productFacets.filter,
          ...filter,
        },
      },
    });

    this.applySearchRequest(
      this.withFacets({
        ...searchRequest,
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      }),
      true,
    );
  }

  private withOrdering(searchRequest: ISearchRequest): ISearchRequest {
    const previousDocumentType = this.state.searchRequest.documentType;

    if (
      searchRequest.documentType !== previousDocumentType &&
      previousDocumentType !== SearchDocumentType.All
    ) {
      this.state.selectedSortOption = DEFAULT_SORT_OPTION;
    }

    return {
      ...searchRequest,
      ordering: getOrdering(
        searchRequest.documentType,
        this.state.selectedSortOption,
      ),
    };
  }

  private withFacets(searchRequest: ISearchRequest): ISearchRequest {
    return {
      ...searchRequest,
      ...convertProductFacetsFilterToRequestParams(
        this.state.productFacets.filter,
      ),
    };
  }

  private getEmptyResponseStateProps() {
    return {
      searchResponseTotals: {},
      searchSamples: [],
      searchResponse: {
        documents: [],
        facets: [],
        totalResults: 0,
      },
    };
  }

  private getErrorStateProps(): Partial<SearchResultsControllerStoreState> {
    return {
      ...this.getEmptyResponseStateProps(),
      apiErrorDetails: undefined,
      previousQuery: undefined,
      searchRequestStatus: SearchRequestStatus.Failed,
    };
  }

  private logBiClickResetFacets() {
    // 99:307 SearchResults - Click Reset Facets
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/307?artifactId=com.wixpress.wix-search-widget
    this.biLogger.searchResultsClickResetFacets({
      availableFacets: getBiAvailableFacets(
        this.state.searchRequest,
        this.state.searchResponse,
      ),
      clickOrigin: 'empty_result_page',
      correlationId: this.getBiSearchCorrelationId(),
      selectedFacets: getBiSelectedFacets(this.state.searchRequest),
      target: this.state.searchRequest.query,
      isDemo: this.state.isDemoContent,
    });
  }

  private logBiDocumentClick(
    p: Pick<
      BiDocumentClickParams,
      | 'documentId'
      | 'documentType'
      | 'pageUrl'
      | 'searchIndex'
      | 'resultClicked'
    > & { clickOrigin: DocumentClickOrigin },
  ): void {
    const { isDemoContent, searchResponseTotals } = this.state;
    // 99:305 searchResults.results.click
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/305?artifactId=com.wixpress.wix-search-widget
    this.biLogger.documentClick({
      correlationId: this.getBiSearchCorrelationId(),
      isDemo: isDemoContent,
      resultsArray: getBiTotals(searchResponseTotals),
      target: this.state.searchRequest.query,
      ...p,
    });
  }

  private redirectOnInvalidPageRequest() {
    const { searchRequest, searchResponse } = this.state;
    const { pageSize, page } = searchRequest.paging;
    const totalPages = getTotalPages(pageSize, searchResponse.totalResults);

    if (page > totalPages) {
      this.applySearchRequest({
        ...searchRequest,
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      });
    }
  }

  private async redirectFromPathParams(): Promise<void> {
    const { experiments } = this.flowAPI;
    if (!experiments.enabled(Spec.UrlQueryParams)) {
      return;
    }

    const pathParams = this.searchLocation.decodeParamsFromPath();
    if (!Object.keys(pathParams).length) {
      return;
    }

    await this.searchLocation.navigateToSearchResults({
      ...this.getSearchRequestParamsFromLocation(),
      ...pathParams,
    });
  }

  private async renderSeo({
    searchRequest,
    searchSamples,
    searchResponse,
  }: Pick<
    SearchResultsControllerStoreState,
    'searchRequest' | 'searchSamples' | 'searchResponse'
  >) {
    const resultType = searchRequest.documentType;
    const searchTerm = searchRequest.query;
    const documents =
      resultType === SearchDocumentType.All
        ? searchSamples
        : searchResponse.documents;

    const baseResultsPageUrl = await this.searchLocation.getSearchResultsAbsoluteUrl();
    const allResultsUrl = this.searchLocation.buildSearchResultsUrl(
      baseResultsPageUrl,
      {
        query: searchTerm,
      },
    );

    const itemData: ISeoItemData = {
      allResultsUrl,
      documents,
      pageUrl: this.wixCodeApi.location.url,
      resultsTotal: searchResponse.totalResults,
      resultType,
      searchTerm,
    };

    this.wixCodeApi.seo.renderSEOTags({
      itemType: ITEM_TYPES.SEARCH_PAGE,
      itemData,
    });
  }

  private handleError(error: unknown) {
    this.setState(this.getErrorStateProps());
    reportError(this.flowAPI.errorMonitor, error);
  }

  async setInitialState(): Promise<void> {
    try {
      await this.redirectFromPathParams();

      // Search correlation ID might be missing (e.g. by direct visit)
      if (!this.biStore.has(BiStoreKey.SearchCorrelation)) {
        this.biStore.set(BiStoreKey.SearchCorrelation, createBiCorrelationId());
      }

      const [searchResultsAbsoluteUrl, documentTypes] = await Promise.all([
        this.searchLocation.getSearchResultsAbsoluteUrl(),
        this.searchSDK.getDocumentTypes(),
      ]);

      this.documentTypes = documentTypes.map((t) => t.documentType);

      if (this.state.isDemoContent) {
        this.setDemoContentOptions({
          documentTypesForSearchResults: this.documentTypes,
        });
      }

      const visibleCategories = getVisibleCategories(
        this.state.settings,
        this.documentTypes,
      );

      const partialState = await this.search(
        this.state.searchRequest,
        visibleCategories,
        this.state.searchRequestStatus,
      );

      this.setState({
        searchResultsAbsoluteUrl,
        documentTypes,
        ...partialState,
      });

      if (this.state.searchRequestStatus !== SearchRequestStatus.Failed) {
        this.redirectOnInvalidPageRequest();
      }
    } catch (error) {
      this.handleError(error);
    }
  }
}
