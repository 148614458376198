import {
  Logger,
  searchRequestStartedParams as BiSearchRequestStartedParams,
  searchRequestFinishedParams as BiSearchRequestFinishedParams,
} from '@wix/bi-logger-wix-search-widget';
import {
  ISearchRequest,
  ISearchResponse,
  ISearchResponseTotals,
} from '@wix/client-search-sdk';

import {
  getBiAvailableFacets,
  getBiSelectedFacets,
  getBiTotals,
} from './utils';

type SearchRequestBiLoggerParams = {
  biLogger: Logger;
  correlationId?: string;
  origin: string;
  isDemoContent: boolean;
  searchRequest: ISearchRequest;
};

export interface ISearchRequestBiLogger {
  started(): void;
  finished(
    searchResponse: ISearchResponse,
    searchResponseTotals: ISearchResponseTotals,
    documentIds: unknown,
  ): void;
  failed(error: unknown): void;
}

export const createSearchRequestBiLogger = ({
  biLogger,
  correlationId,
  origin,
  isDemoContent,
  searchRequest,
}: SearchRequestBiLoggerParams): ISearchRequestBiLogger => {
  let startTime: number;

  const commonParams: Partial<
    BiSearchRequestStartedParams & BiSearchRequestFinishedParams
  > = {
    correlationId,
    documentType: searchRequest.documentType,
    isDemo: isDemoContent,
    orderingDirection: searchRequest.ordering?.ordering?.[0]?.direction,
    orderingFieldName: searchRequest.ordering?.ordering?.[0]?.fieldName,
    target: searchRequest.query,
  };

  const logSearchRequestFinished = (
    params: Partial<BiSearchRequestFinishedParams>,
  ) => {
    // 99:304 SearchResults - request result was received
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/304?artifactId=com.wixpress.wix-search-widget
    biLogger.searchRequestFinished({
      ...commonParams,
      ...params,
      loadingDuration: Date.now() - startTime,
      origin,
    });
  };

  return {
    started() {
      startTime = Date.now();
      // 99:303 searchResults - request was sent to server
      // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/303?artifactId=com.wixpress.wix-search-widget
      biLogger.searchRequestStarted(commonParams);
    },
    finished(searchResponse, searchResponseTotals, documentIds) {
      logSearchRequestFinished({
        documentIds: JSON.stringify(documentIds),
        availableFacets: getBiAvailableFacets(searchRequest, searchResponse),
        selectedFacets: getBiSelectedFacets(searchRequest),
        resultCount: searchResponse.totalResults,
        resultsArray: getBiTotals(searchResponseTotals),
        success: true,
      });
    },
    failed(error) {
      logSearchRequestFinished({
        error: String(error),
        success: false,
      });
    },
  };
};
