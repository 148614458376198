import { IControllerConfig } from '@wix/native-components-infra/dist/src/types/types';

export const getPublicData = ({ publicData }: IControllerConfig) => {
  const componentData = publicData.COMPONENT || {};
  const appData = publicData.APP || {};

  // TODO: This was ported from old code, but we should investigate why we are
  // merging with app data here - suspect this is not required.
  return { ...appData, ...componentData };
};
