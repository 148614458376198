import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getSettingsValues,
  ISettingsValues,
} from '@wix/yoshi-flow-editor/tpa-settings';
import { CategoryList } from '@wix/search-settings-client';

import settingsParams from '../../settingsParams';

export type Settings = ISettingsValues<typeof settingsParams> & {
  categoryList: CategoryList;
};

export const extractSettings = (
  {
    controllerConfig,
    environment,
    translations,
    experiments,
  }: ControllerFlowAPI,
  categoryList: CategoryList,
): Settings => {
  const { isRTL, isMobile, language } = environment;
  const componentSettings = getSettingsValues(
    controllerConfig.config.publicData,
    settingsParams,
    {
      t: translations.t,
      isRTL,
      isMobile,
      language,
      experiments: experiments as any,
    },
  );

  return { categoryList, ...componentSettings };
};
